<template>
  <b-row>

    <b-col sm="6">
      <!-- Description -->
      <validation-provider
        #default="validationContext"
        name="Description"
        rules="required"
      >
        <b-form-group
          label="Description"
          label-for="description"
        >
          <b-form-input
            id="description"
            v-model="activityData.description"
            :state="getValidationState(validationContext)"
            placeholder="Description"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col sm="6">
      <!-- Description AR -->
      <validation-provider
        #default="validationContext"
        name="Description AR"
        rules="required"
      >
        <b-form-group
          label="Description AR"
          label-for="description-ar"
        >
          <b-form-input
            id="description-ar"
            v-model="activityData.descriptionAr"
            :state="getValidationState(validationContext)"
            placeholder="Description AR"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Is Sub Activity -->
    <b-col
      v-if="page == 'create' || page == 'edit-subactivity'"
      sm="6"
    >
      <b-form-group
        label="Is Sub Activity?"
        label-for="is-subactivity"
      >
        <b-form-checkbox
          id="is-subactivity"
          v-model="activityData.isSubActivity"
          checked="true"
          name="is-subactivity"
          switch
          :disabled="page == 'edit-subactivity'"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Activity -->
    <b-col
      v-if="activityData.isSubActivity"
      sm="6"
    >
      <validation-provider
        #default="validationContext"
        name="Activity"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Activity"
          label-for="activity"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="activityData.activityId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="activityOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="activity"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
  BFormInvalidFeedback, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import useActivitiesForm from './useActivitiesForm'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,

    vSelect,

    //  Form Validation
    ValidationProvider,
  },

  directives: {
    Ripple,
  },
  props: {
    activityData: {
      type: Object,
      required: true,
    },
    page: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup() {
    const {
      getValidationState,
    } = formValidation()

    const { activityOptions } = useActivitiesForm()

    return {
      //  Form
      getValidationState,

      // Options
      activityOptions,
    }
  },
}
</script>
